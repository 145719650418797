/**
 * Property Refinement
 */

console.log('property refinement');

if (jQuery().select2) {

	//https://stackoverflow.com/questions/26730167/how-to-pre-select-values-in-select2-multi-select
	//how you populate array
	//var PRESELECTED_FRUITS = [ '1','2','3'];
	//$('.myList').select2({}).select2('val', PRESELECTED_FRUITS);

	var expire = new Date();
	//set expiry to current time plus 15 minutes in milliseconds
	expire.setTime(expire.getTime() + (15 * 60 * 1000));

	$("#property_type").on("select2:select", function (e) {
		var select_val = $(e.currentTarget).val();
		$.cookie("type", select_val, { expires: expire });
	});
	$('#property_type').val($.cookie("type")).trigger('change.select2');


	$("#property_bedrooms").on("select2:select", function (e) {
		var select_val = $(e.currentTarget).val();
		$.cookie("beds", select_val, { expires: expire });
	});
	$('#property_bedrooms').val($.cookie("beds")).trigger('change.select2');


	$("#property_min_price").on("select2:select", function (e) {
		var select_val = $(e.currentTarget).val();
		$.cookie("min_price", select_val, { expires: expire });
	});
	$('#property_min_price').val($.cookie("min_price")).trigger('change.select2');


	$("#property_max_price").on("select2:select", function (e) {
		var select_val = $(e.currentTarget).val();
		$.cookie("max_price", select_val, { expires: expire });
	});
	$('#property_max_price').val($.cookie("max_price")).trigger('change.select2');

	$("#property_city").on("select2:select", function (e) {
		var select_val = $(e.currentTarget).val();
		$.cookie("city", select_val, { expires: expire });
	});
	$('#property_city').val($.cookie("city")).trigger('change.select2');

	$("#property_bathrooms").on("select2:select", function (e) {
		var select_val = $(e.currentTarget).val();
		$.cookie("bathrooms", select_val, { expires: expire });
	});
	$('#property_bathrooms').val($.cookie("bathrooms")).trigger('change.select2');


	$("#property_min-area").on("keyup", function (e) {
		var select_val = $(e.currentTarget).val();
		$.cookie("min-area", select_val, { expires: expire });
	});
	$('#property_min-area').val($.cookie("min-area")).trigger('change.select2');


	$("#property_max-area").on("keyup", function (e) {
		var select_val = $(e.currentTarget).val();
		$.cookie("max-area", select_val, { expires: expire });

	});
	$('#property_max-area').val($.cookie("max-area")).trigger('change.select2');

	$("#property_keyword").on("keyup", function (e) {
		var select_val = $(e.currentTarget).val();
		$.cookie("keyword", select_val, { expires: expire });

	});
	$('#property_keyword').val($.cookie("keyword")).trigger('change.select2');


	$("#property_max_garage").on("select2:select", function (e) {
		var select_val = $(e.currentTarget).val();
		$.cookie("max_garage", select_val, { expires: expire });

	});
	$('#property_max_garage').val($.cookie("max_garage")).trigger('change.select2');


	$("#property_min_lot_size").on("select2:select", function (e) {
		var select_val = $(e.currentTarget).val();
		$.cookie("min_lot", select_val, { expires: expire });
	});
	$('#property_min_lot_size').val($.cookie("min_lot")).trigger('change.select2');


	$("#property_max_lot_size").on("select2:select", function (e) {
		var select_val = $(e.currentTarget).val();
		$.cookie("max_lot", select_val, { expires: expire });
	});
	$('#property_max_lot_size').val($.cookie("max_lot")).trigger('change.select2');

	$("#property_area").on("select2:select", function (e) {
		var select_val = $(e.currentTarget).val();
		$.cookie("area", select_val, { expires: expire });
	});
	$('#property_area').val($.cookie("area")).trigger('change.select2');


	$("#property_keyword").on("select2:select", function (e) {
		var select_val = $(e.currentTarget).val();
		$.cookie("keyword", select_val, { expires: expire });
	});
	$('#property_keyword').val($.cookie("keyword")).trigger('change.select2');


	$("#property_address").on("select2:select", function (e) {
		var select_val = $(e.currentTarget).val();
		$.cookie("address", select_val, { expires: expire });
	});
	$('#property_address').val($.cookie("address")).trigger('change.select2');

	$("#property_sub").on("select2:select", function (e) {
		var select_val = $(e.currentTarget).val();
		$.cookie("sub", select_val, { expires: expire });
	});
	$('#property_sub').val($.cookie("sub")).trigger('change.select2');



	$('.clear-search').on('click', function (e) {


		$.cookie("type", "", { expires: expire });
		$.cookie("beds", "", { expires: expire });
		$.cookie("min_price", "", { expires: expire });
		$.cookie("max_price", "", { expires: expire });
		$.cookie("city", "", { expires: expire });
		$.cookie("sub", "", { expires: expire });
		$.cookie("bathrooms", "", { expires: expire });
		$.cookie("min-area", "", { expires: expire });
		$.cookie("max-area", "", { expires: expire });
		$.cookie("address", "", { expires: expire });
		$.cookie("keyword", "", { expires: expire });
		$.cookie("area", "", { expires: expire });
		$.cookie("max_lot", "", { expires: expire });
		$.cookie("min_lot", "", { expires: expire });
		$.cookie("max_garage", "", { expires: expire });

		$('#property_type').val("").trigger('change.select2');
		$('#property_bedrooms').val("").trigger('change.select2');
		$('#property_min_price').val("").trigger('change.select2');
		$('#property_max_price').val("").trigger('change.select2');
		$('#property_city').val("").trigger('change.select2');
		$('#property_bathrooms').val("").trigger('change.select2');
		$('#property_min-area').val("").trigger('change.select2');
		$('#property_max-area').val("").trigger('change.select2');
		$('#property_max_garage').val("").trigger('change.select2');
		$('#property_min_lot_size').val("").trigger('change.select2');
		$('#property_max_lot_size').val("").trigger('change.select2');
		$('#property_area').val("").trigger('change.select2');
		$('#property_keyword').val("").trigger('change.select2');
		$('#property_address').val("").trigger('change.select2');
		$('#property_sub').val("").trigger('change.select2');



	});


	const $select2 = $('.property-refinement__select2');
	const selectOptions = {
		width: 'off',
	};

	$select2.select2(selectOptions)
		.on("select2:open", function (e) {
			$(this).parents('.property-refinement__select').addClass('property-refinement__active');
			$('.select2-dropdown').hide();
			setTimeout(function () {
				$('.select2-dropdown').slideDown(200);
			}, 10);
		})
		.on("select2:closing", function (e) {
			$(this).parents('.property-refinement__select').removeClass('property-refinement__active');
		});
}

$('.search-buttons__advance-btn').on('click', function (e) {

	$(this).find('#property-refinement__search').toggle('400');

	$(this).toggleClass('is-active');

	if ($(this).hasClass('is-active')) {
		$('.property-refinement__collapsed-fields').slideDown(400);
		//$('.property-refinement__collapsed-fields').css( "position: relative");
	} else(
		$('.property-refinement__collapsed-fields').slideUp(400)
	)

});

//Open Search Form More Features fields
$('#property-refinement__open-more-options').on('click', function (e) {
	e.preventDefault();

	$(this).toggleClass('is-active');

	$('.property-refinement__more-options').slideToggle(200);
});


