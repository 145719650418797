//--------------------------------------------------------------
// INDEX
//
// Entry point file for webpack.
//--------------------------------------------------------------

// Lazysizes
require('lazysizes/lazysizes'); // @see https://github.com/aFarkas/lazysizes
require('lazysizes/plugins/parent-fit/ls.parent-fit'); // @see https://github.com/aFarkas/lazysizes#parent-fit-extension
require('lazysizes/plugins/bgset/ls.bgset'); // @see https://github.com/aFarkas/lazysizes#bgset-plugin---lazy-responsive-background-image
require('lazysizes/plugins/unveilhooks/ls.unveilhooks'); // @see https://github.com/aFarkas/lazysizes/tree/gh-pages/plugins/unveilhooks
require('lazysizes/plugins/respimg/ls.respimg'); // IE 11. Only uncomment and use for support in IE.

// Property Refinement
import './jquery.cookie';
import './property-refinement';

// Slick Carousel
// import './slick-init';

import './custom';
import './map';
import './fresco';


