(function ($) {

    $(document).ready(function () {

        'use strict';


        // smooth scroll
        var smoothScroll = function () {
            $("a").on('click', function (event) {
                // Make sure this.hash has a value before overriding default behavior
                if (this.hash !== "") {
                    // Prevent default anchor click behavior
                    event.preventDefault();

                    // Store hash
                    var hash = this.hash;

                    // Using jQuery's animate() method to add smooth page scroll
                    $('html, body').animate({
                        scrollTop: $(hash).offset().top
                    }, 800, function () {

                        // Add hash (#) to URL when done scrolling (default click behavior)
                        window.location.hash = hash;
                    });
                }
            });
        };





            var runSlick = function () {

                $('.carousel-slider').slick({
                    infinite: false,
                    lazyLoad: 'ondemand',
                    autoplay: true,
                    autoplaySpeed: 4000,
                    speed: 300,
                    slidesToShow: 1,
                    arrows: true,
                    fade: true,
                    prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-chevron-left" aria-hidden="true"></i> Previous</button>',
                    nextArrow: '<button type="button" class="slick-next"><i class="fa fa-chevron-right" aria-hidden="true"></i> Previous</button>',
                    //asNavFor: '.slider-nav',
                });


                /*if ($(window).width() > 960) {

                    $('.slider-nav').slick({
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        asNavFor: '.carousel-slider',
                        lazyLoad: 'ondemand',
                        autoplay: true,
                        autoplaySpeed: 4000,
                        arrows: true,
                        centerMode: true,
                        prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-chevron-left" aria-hidden="true"></i> Previous</button>',
                        nextArrow: '<button type="button" class="slick-next"><i class="fa fa-chevron-right" aria-hidden="true"></i> Previous</button>',
                        focusOnSelect: true,
                        accessibility: true,
                        responsive: [
                            {
                                breakpoint: 960,
                                settings: {
                                    autoplay: false,
                                    slidesToShow: 2,
                                }
                            },
                            {
                                breakpoint: 640,
                                settings: {
                                    autoplay: false,
                                    slidesToShow: 1,
                                }
                            }
                        ]
                    });
                } else {
                    $('.slider-nav-wrap').hide()
                }*/

            };



        // active our mobile menu
        var runMobleNav = function () {

            $('.hamburger').click(function (e) {
                e.preventDefault();
                $(this).parent().toggleClass('change');
                $('#site-navigation').toggleClass('desktop');
                $('#site-navigation').toggleClass('mobile-nav');
                $('body').toggleClass('mob-menu-active');
            });

        };


        // close our mobile on if they stretch out the screen.
        var runMobileClose = function () {
            $(window).resize(function () {
                if ($(window).width() > 960 && $('body').hasClass('mob-menu-active')) {
                    $('body').removeClass('mob-menu-active');
                    $('.wrap').removeClass('change');
                    $('#site-navigation').removeClass('mobile-nav');
                    $('#site-navigation').addClass('desktop');
                }
            });
        };



        // start everything brah!
        var init = function () {
            var el = document.getElementsByClassName('carousel-slider'),
                $el = $(el);
            if (el.length) {
                runSlick($el);
                console.info('Initialized homepage slick fullscreen.');
            }

            smoothScroll();
            runMobleNav();
            runMobileClose();

        };

        $(document).ready(function () {
            // lets initialize everything
        init();

        });
    });


})(jQuery);










